import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect } from "react";

interface UserRolesSelectProps {
  currentRole: string;
  setCurrentRole: (role: string) => void;
}

export default function UserRolesSelect(props: UserRolesSelectProps) {
  const [role, setRole] = React.useState("");
  const { currentRole, setCurrentRole } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentRole(event.target.value as string);
  };

  useEffect(() => {
    setRole(currentRole);
  }, [currentRole]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Nível de Acesso</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} label="role" onChange={handleChange}>
          <MenuItem value={"admin"}>Admin</MenuItem>
          <MenuItem value={"admin"}>Gestão</MenuItem>
          <MenuItem value={"salesman"}>Vendedor</MenuItem>
          <MenuItem value={"pericia_creator"}>Perito</MenuItem>
          <MenuItem value={"user_privileged"}>Técnico perito</MenuItem>
          <MenuItem value={"user"}>Técnico</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
