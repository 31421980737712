import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { useRef } from "react";

interface DeletePericiaButtonProps {
  props: Props;
}

interface Props {
  customFunction: () => void;
  message: string;
  confirmDialogText: string;
  buttonText: string;
  loading: boolean;
  disabled: boolean;
}

const CustomButton: React.FC<DeletePericiaButtonProps> = ({ props }) => {
  const { message, buttonText, confirmDialogText, loading, disabled, customFunction } = props;
  const [open, setOpen] = React.useState(false);

  const deleteRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = () => {
    if (deleteRef?.current?.value.toLocaleLowerCase() !== confirmDialogText) {
      return alert(`Digite ${confirmDialogText} para confirmar`);
    }

    customFunction();
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mb: 1, mt: 1 }}
        onClick={handleClickOpen}
        loading={loading}
        disabled={disabled}
      >
        {buttonText}
      </LoadingButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Marcar fatura como recebida</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message} Digite <strong>{confirmDialogText}</strong> no campo abaixo para confirmar.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={confirmDialogText}
            type="text"
            fullWidth
            variant="standard"
            inputRef={deleteRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClickConfirm} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomButton;
