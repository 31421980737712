import { Grid, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material";
import FlexContainer from "../../../shared/ui/container/flex-container.component";
import Title from "../../typography/title.component";
import { formatCurrency } from "../../../utils/helpers/numbers";
import { RepairRecord } from "../../../entities/repair-record";
import { useState } from "react";
import { Pericia } from "../../../entities/pericia";
import UpdatePericiaDialog from "../../../features/pericia/update-pericia/ui/update-pericia-dialog.component";

interface RepairRecordsListComponentProps {
  props: Props;
}

interface Props {
  repairRecords: RepairRecord[];
  totalPrice: number;
  handlePericiaUpdated: () => void;
}

const RepairRecordsAdminList: React.FC<RepairRecordsListComponentProps> = ({ props }) => {
  const { repairRecords, totalPrice, handlePericiaUpdated } = props;
  const [pericia, setPericia] = useState<Pericia | null>(null);
  const [updatePericiaDialogOpen, setUpdatePericiaDialogOpen] = useState(false);

  const handlePericiaClick = (pericia: Pericia) => {
    setPericia(pericia);
    handleToggleDialog();
  };

  const handleToggleDialog = () => {
    setUpdatePericiaDialogOpen(!updatePericiaDialogOpen);
  };

  return (
    <FlexContainer>
      <Title value="Reparos Finalizados" />
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12}>
          <ListItem
            id="record-list-header"
            divider
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "center"
            }}
          >
            {["CLIENTE", "CARRO", "PLACA", "CHASSI", "DATA", "VALOR"].map((item, idx) => (
              <ListItemText
                key={`record-list-header-${idx}`}
                id={`record-list-header-${idx}`}
                primary={item}
                sx={{ flex: "1", fontWeight: "bold" }}
              />
            ))}
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={12} mb={6}>
          {repairRecords.map((record) => (
            <ListItem key={record.id} divider>
              <ListItemButton
                key={record.id}
                onClick={handlePericiaClick.bind(this, record.pericia)}
                role={undefined}
                dense
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  color: record.pericia.cashPayedAmount && record.pericia.cashPayedAmount > 0 ? "green" : "text.primary"
                }}
              >
                <ListItemText id={record.id} primary={`${record.pericia.costumer.name}`} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.model}`} sx={{ flex: "1", textAlign: "center" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.plate}`} sx={{ flex: "1" }} />
                <ListItemText id={record.id} primary={`${record.pericia.car.chassisNumber}`} sx={{ flex: "1" }} />
                <ListItemText
                  id={record.id}
                  primary={record.finishedAt && record.finishedAt.toLocaleDateString("pt-BR")}
                  sx={{ flex: "1" }}
                />
                <ListItemText
                  id={record.id}
                  primary={formatCurrency(record.pericia.cashPayedAmount > 0 ? record.pericia.cashPayedAmount : record.payedValue)}
                  sx={{ flex: "1" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {pericia && (
            <UpdatePericiaDialog
              pericia={pericia}
              onPericiaUpdated={handlePericiaUpdated}
              open={updatePericiaDialogOpen}
              handleToggle={handleToggleDialog}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="flex-end">
          <TextField label="Total" variant="standard" disabled value={formatCurrency(totalPrice)} />
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default RepairRecordsAdminList;
