import { Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import { createNewInvoice } from "../../entities/invoice/model/invoice";
import { UpdateInvoiceHeader } from "../../features/invoice/update-invoice/ui/update-invoice-header";
import { useNavigate, useParams } from "react-router-dom";
import { useGetInvoiceById } from "../../features/invoice/get-invoice-by-id/api/useGetInvoiceById";
import updateInvoiceListComponent from "../../features/invoice/update-invoice/ui/update-invoice-list.component";
import { LoadingButton } from "@mui/lab";
import DeleteButton from "../../shared/ui/buttons/delete-button.component";
import { ChangeEvent, useEffect, useState } from "react";
import { formatCurrency } from "../../utils/helpers/numbers";
import { useCreateInvoicePDF } from "../../features/invoice/update-invoice/api/useCreateInvoicePDF";
import { ToastContainer, toast } from "react-toastify";
import { invoiceService } from "../../services/invoice/invoice.service";
import { useUpdateInvoiceDate } from "../../features/invoice/update-invoice-date/api/useUpdateInvoiceDate";
import { useUpdateInvoiceIvaPercentage } from "../../features/invoice/update-iva-percentage/api";
import { useUpdateInvoiceText } from "../../features/invoice/update-invoice-text/api";
import { updateInvoiceCustomCount } from "../../storage/supabase/invoice/invoice.storage";
import CustomButton from "../../shared/ui/buttons/custom-button.component";
import { useUpdateInvoice } from "../../features/invoice/update-invoice-payed/api";
import { set } from "lodash";

const INITIAL_INVOICE = createNewInvoice();

interface InvoiceNewDate {
  id: string;
  date: Date;
  shouldUpdate: boolean;
}

const INITIAL_INVOICE_NEW_DATE: InvoiceNewDate = {
  id: "",
  date: new Date(),
  shouldUpdate: false
};

export const UpdateInvoicePage = () => {
  const { invoiceID } = useParams();
  const navigate = useNavigate();
  const [invoiceNewDate, setInvoiceNewDate] = useState<InvoiceNewDate>(INITIAL_INVOICE_NEW_DATE);
  const {
    data: invoice,
    error: errorFetchingInvoice,
    isLoading: isLoadingFetchingInvoice,
    refetch: refecthInvoice
  } = useGetInvoiceById(invoiceID || "");
  const [displayTotalHours, setDisplayTotalHours] = useState<boolean>(false);
  const [invoiceCustomCount, setInvoiceCustomCount] = useState<number>(0);
  const [ivaPercentage, setIvaPercentage] = useState<number>(0);
  const [invoiceText, setInvoiceText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [updateInvoicePros, setUpdateInvoicePros] = useState({ id: "", enabled: false });
  const { data: updateInvoiceDateRes, error: errorUpdatingInvoiceDate } = useUpdateInvoiceDate(invoiceNewDate);
  const { data: ivaPercentageUpdateRes } = useUpdateInvoiceIvaPercentage({ id: invoiceID || "", new_iva_percentage: ivaPercentage });
  const { data: updateInvoiceTextRes } = useUpdateInvoiceText({ id: invoiceID || "", new_invoice_text: invoiceText });
  const { data: updateInvoiceRes, isFetching: isPaying } = useUpdateInvoice({ ...updateInvoicePros });

  const handleInvoiceCustomCountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInvoiceCustomCount(Number(value));
  };

  const handleUpdateDate = (date: Date) => {
    if (!invoiceID) return;

    setInvoiceNewDate({ shouldUpdate: true, id: invoiceID, date });
  };

  const handleInvoiceCustomCountOnBlur = async () => {
    if (!invoiceID) return;

    if (invoiceCustomCount > 0) {
      const res = await updateInvoiceCustomCount(invoiceID, invoiceCustomCount);
      if (res.error) {
        console.error(res.error);
        toast.error("Erro ao atualizar número da fatura.");
        return;
      }
      refecthInvoice();
      toast.success("Número da fatura atualizado com sucesso.");
    }
  };

  const handleToggleDisplayTotalHours = () => {
    setDisplayTotalHours(!displayTotalHours);
  };

  const handleInvoiceTextUpdate = (newInvoiceText: string) => {
    if (!invoiceID) return;

    setInvoiceText(newInvoiceText);
  };

  const handleIvaPercentageUpdate = (newIvaPercentage: number) => {
    if (!invoiceID) return;

    setIvaPercentage(newIvaPercentage);
  };

  const handleSavePDF = () => {
    if (!invoice) return;

    const invoicePdfDTO = {
      costumerName: invoice.costumer.name,
      costumerAddress: invoice.costumer.fullAddress,
      costumerLanguage: invoice.costumer.language,
      createdAt: invoice.createdAt.toLocaleDateString("pt-BR"),
      totalPrice: formatCurrency(invoice.totalPrice),
      totalIVA: formatCurrency(calculateTotalPriceWithIVA(invoice.totalPrice, invoice.ivaPercentage)),
      IVA: invoice.ivaPercentage,
      items: [...invoice.items],
      displayTotalHours,
      invoiceNumber: invoice.invoiceCustomCount > 0 ? invoice.invoiceCustomCount : invoice.invoiceCount,
      invoiceText: invoice.invoiceText || "Riparazione Alternativa Grandine"
    };

    useCreateInvoicePDF.execute(invoicePdfDTO);
  };

  const handleUpdateInvoice = async () => {
    if (!invoiceID) return;

    setUpdateInvoicePros({ id: invoiceID, enabled: true });
  };

  const handleDeleteInvoice = async () => {
    if (!invoiceID) return;

    setDisabled(true);
    setLoading(true);
    // const { error } = await invoiceService.deleteInvoice(invoiceID);
    // if (error) {
    //   console.error(error);
    //   toast.error("Erro ao deletar fatura.");
    //   setLoading(false);
    //   setDisabled(false);
    //   return;
    // }

    // setLoading(false);
    // toast.success("Fatura deletada com sucesso. Redirecionando...", { autoClose: 600 });
    // setTimeout(() => {
    //   navigate("/invoices/list");
    // }, 1200);
  };

  useEffect(() => {
    if (invoice) {
      setInvoiceCustomCount(invoice.invoiceCustomCount);
      setInvoiceNewDate({ shouldUpdate: false, id: invoice.id, date: invoice.createdAt });
    }
  }, [invoice]);

  useEffect(() => {
    if (!ivaPercentageUpdateRes) return;
    if (!invoiceID) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Porcentagem IVA atualizada com sucesso.");
    }, 1000);
  }, [ivaPercentageUpdateRes]);

  useEffect(() => {
    if (!invoiceID) return;
    if (!updateInvoiceTextRes) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Texto de rodapé com sucesso.");
    }, 1000);
  }, [updateInvoiceTextRes]);

  useEffect(() => {
    if (!updateInvoiceRes) return;

    refecthInvoice();
    setTimeout(() => {
      toast.success("Fatura paga com sucesso.");
    }, 1000);
  }, [updateInvoiceRes]);

  useEffect(() => {
    if (!updateInvoiceDateRes) return;

    toast.success("Data da fatura atualizada com sucesso.", { autoClose: 800 });
    refecthInvoice();
  }, [updateInvoiceDateRes]);

  if (errorUpdatingInvoiceDate) {
    toast.error("Erro ao atualizar data da fatura.");
  }

  return (
    <FlexContainer>
      <Typography variant="h4">Fatura</Typography>
      <UpdateInvoiceHeader
        invoice={invoice || INITIAL_INVOICE}
        invoiceNewDate={invoiceNewDate || new Date()}
        totalPrice={invoice?.totalPrice || 0}
        ivaPercentage={invoice?.ivaPercentage || 0}
        invoiceText={invoice?.invoiceText || "Riparazione Alternativa Grandine"}
        totalIVA={calculateIVA(invoice?.totalPrice || 0, invoice?.ivaPercentage || 0)}
        totalPriceWithIVA={calculateTotalPriceWithIVA(invoice?.totalPrice || 0, invoice?.ivaPercentage || 0)}
        invoiceCustomCount={invoiceCustomCount}
        handleUpdateDate={handleUpdateDate}
        handleInvoiceTextUpdate={handleInvoiceTextUpdate}
        handleInvoiceCustomCountChange={handleInvoiceCustomCountChange}
        handleInvoiceCustomCountOnBlur={handleInvoiceCustomCountOnBlur}
        handleToggleDisplayTotalHours={handleToggleDisplayTotalHours}
        handleIvaPercentageUpdate={handleIvaPercentageUpdate}
      />
      {invoice && (
        <>
          {updateInvoiceListComponent({
            items: invoice.items,
            displayTotalHours
          })}
          <LoadingButton fullWidth variant="contained" sx={{ mt: 10, mb: 1 }} onClick={handleSavePDF} loading={loading} disabled={disabled}>
            GERAR PDF
          </LoadingButton>
          <CustomButton
            props={{
              customFunction: handleUpdateInvoice,
              message: "Tem certeza que deseja marcar esta fatura como recebida? Esta ação não pode ser desfeita.",
              buttonText: "FATURA RECEBIDA",
              confirmDialogText: "recebida",
              loading: isPaying,
              disabled: invoice?.payed
            }}
          />
          <DeleteButton
            props={{
              deleteFunction: handleDeleteInvoice,
              message: "Tem certeza que deseja excluir esta fatura? Esta ação não pode ser desfeita.",
              buttonText: "DELETAR FATURA",
              loading,
              disabled
            }}
          />
        </>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};

const calculateIVA = (totalPrice: number, iva: number) => {
  return (totalPrice * iva) / 100;
};

const calculateTotalPriceWithIVA = (totalPrice: number, iva: number) => {
  return totalPrice + calculateIVA(totalPrice, iva);
};
