import "react-toastify/dist/ReactToastify.css";
import { TextareaAutosize, Typography } from "@mui/material";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import PericiaHeader from "../../entities/pericia/ui/pericia-header.component";
import { CreateNewPericia, PericiaFinishedInput, PericiaInsuranceForm, usePericia } from "../../entities/pericia";
import { useEffect, useState } from "react";
import { Car, CreateNewCar } from "../../entities/car";
import { useGetPericiaById } from "../../features/pericia/get-pericia-by-id/api/useGetPericiaById";
import { useNavigate, useParams } from "react-router-dom";
import PericiaImg from "../../entities/pericia/ui/pericia-img.component";
import PericiaAddtionalCost from "../../entities/pericia/ui/pericia-addtional-cost.component";
import PericiaTable from "../../entities/pericia/ui/periciaTable.component";
import PericiaCostumerPrice from "../../entities/pericia/ui/pericia-costumer-price.component";
import PericiaStatus from "../../entities/pericia/ui/pericia-status.component";
import { PericiaInvoce } from "../../entities/pericia/ui/pericia-invoice.component";
import { useGetInvoiceByPericiaId } from "../../features/invoice/get-invoice-by-pericia-id/api/useGetInvoiceByPericiaId";
import DeleteButton from "../../shared/ui/buttons/delete-button.component";
import TechnicianAmountToPayForm from "../../components/technician/amount-to-pay-form.component";
import TablePDFGenerator from "../../components/pdf/table/table-pdf-component";
import PDFGenerator from "../../components/pdf/pdf.component";
import { useUpdatePericia } from "../../features/pericia/update-pericia/api/useUpdatePericia";
import { toast, ToastContainer } from "react-toastify";
import { getFinishedRepairRecordsByPericiaId } from "../../storage/supabase/repair-records/repair-records.storage";
import { createTechnicianPaymentService } from "../../services/payment-service/create-technician-payment.service";
import { deletePericiaByCarId } from "../../storage/supabase/pericia/pericia.storage";
import { useDeleteRepairRecord } from "../../features/repair-record/list-repair-records-by-user-id/api";
import UserAutocompleteSearchBar from "../../entities/user/ui/users-autocomplete-search-bar.component";
import { User, makeNewUser } from "../../entities/user";
import { PaperCard } from "../../shared/ui/paper-card/paper-card.component";
import { LoadingButton } from "@mui/lab";
import { useCreateRepairRecordByUserId } from "../../features/repair-record/list-repair-records-by-user-id/api/useCreateRepairRecordByUserId";
import { useGetTechnicianPaymentRecord } from "../../features/technician-payment/get-technician-payment/useGetTechnicianPaymentRecord";
import { CreatePericiaNoteForm } from "../../features/pericia-note/ui/create-pericia-note-form";
import BasicAccordion from "../../shared/ui/accordion";
import { useListPericiaNotes } from "../../features/pericia-note/api/useListPericiaNotes";
import { PericiaNotes } from "../../entities/pericia-notes/model/pericia-notes";
import FinishedRepairRecordsList from "../../entities/repair-record/ui/finished-repair-records-list.component";
import { FinishedRepairRecord } from "../../features/repair-record/model/finished-repair-record";
import { useListPericiaComments } from "../../features/pericia-comment/list-pericia-comments/api/useListPericiaComments";
import { PericiaCommentsList } from "../../features/pericia-comment/ui/pericia-comments-list.component";
import { UploadPericiaPhotoComponent } from "../../features/pericia-photo/ui/upload-pericia-photo.component";
import { PericiaPhotosList } from "../../features/pericia-photo/ui/pericia-photos-list.component";
import { useListPericiaPhotos } from "../../features/pericia-photo/api/useListPericiaPhotos";
import { usePericiaPhotoUploader } from "../../features/pericia-photo/upload-photo/api/usePericiaPhotoUploader";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import supabase from "../../shared/database/supabase";
import { CashPayedAmountComponent } from "../../features/pericia/update-pericia-cash-payed-amount/ui/cash-payed-amount.component";

interface RepairRecordToDelete {
  periciaID: string;
  userID: string;
}

interface RepairRecordToCreate {
  periciaId: string;
  userId: string;
  done: boolean;
}

const INITIAL_REPAIR_RECORD_TO_CREATE: RepairRecordToCreate = {
  periciaId: "",
  userId: "",
  done: true
};

function makeNewRepairRecordToDelete(): RepairRecordToDelete {
  return {
    periciaID: "",
    userID: ""
  };
}

export const PericiaUpdatePage = () => {
  const user = supabase.auth.user() as UserWithRole;
  const { periciaID } = useParams();
  const [periciaHasBeenFetched, setPericiaHasBeenFetched] = useState(false);
  //TODO refact
  const [amountToPay, setAmountToPay] = useState(0);
  const [carBeingCreated, setCarBeingCreated] = useState<Car>(CreateNewCar());
  const [repairRecords, setRepairRecords] = useState<FinishedRepairRecord[]>([]);
  const {
    pericia,
    updatePericia,
    updatePericiaCostumer,
    updatePericiaCostumerPrice,
    updatePericiaCar,
    updatePericiaPricePerHour,
    updatePericiaShouldUnmount,
    updatePericiaUnmountPrice,
    updatePericiaDate,
    updatePericiaAddtionalCost,
    updatePericiaAddtionalCostDescription,
    updatePericiaFinished,
    updateCarPart,
    updatePericiaInsuranceHours,
    updatePericiaIsBilled,
    updatePericiaIsDone,
    updatePericiaIsPriceCalculated,
    findCarPart
  } = usePericia();
  const [periciaToUpdate, setPericiaToUpdate] = useState(CreateNewPericia());
  const [repairRecorToCreate, setRepairRecorToCreate] = useState<RepairRecordToCreate>(INITIAL_REPAIR_RECORD_TO_CREATE);
  const [repairRecordToDelete, setRepairRecordToDelete] = useState<RepairRecordToDelete>(makeNewRepairRecordToDelete());
  const {
    data: deleteRepairRecordData,
    error: deleteRepairRecordError,
    isLoading: deleteRepairRecordIsLoading
  } = useDeleteRepairRecord(repairRecordToDelete);
  const { data: fetchedPericia, error: errorFetchingPericia, isLoading, refetch: refetchPericia } = useGetPericiaById(periciaID || "");
  const { data: invoice, error: errorFetchingInvoice, isLoading: isLoadingInvoice } = useGetInvoiceByPericiaId(periciaID || "");
  const { data: periciaUpdated, error: errorUpdatingPericia, isLoading: isLoadingUpdatingPericia } = useUpdatePericia(periciaToUpdate);
  const {
    data: createRepairRecord,
    error: errorCreatingRepairRecord,
    isLoading: isLoadingCreatingRepairRecord
  } = useCreateRepairRecordByUserId(repairRecorToCreate);
  const {
    data: fetchedTechnicianPaymentRecord,
    error: errorFetchingTechnicianPaymentRecord,
    isLoading: isLoadingFetchingTechnicianPaymentRecord
  } = useGetTechnicianPaymentRecord({ id_pericia: pericia.id });
  const {
    data: periciaNotes,
    error: errorFetchingPericiaNotes,
    isLoading: isLoadingPericiaNotes,
    refetch: refetchPericiaNotes
  } = useListPericiaNotes({ id_pericia: pericia.id });
  const { data: periciaComments, error: errorListingPericiaComments } = useListPericiaComments({ periciaId: pericia.id });
  const {
    data: periciaPhotos,
    error: errorListingPericiaPhotos,
    refetch: refetchPericiaPhotos
  } = useListPericiaPhotos({ id_pericia: pericia.id });
  const { image, handleSetImage, uploadPhotoRes, isUploadingImage } = usePericiaPhotoUploader();
  const [isLoadingUpdatingTechnicianPaymentRecord, setIsLoadingUpdatingTechnicianPaymentRecord] = useState(false);
  const [userToCreateRepairRecord, setUserToCreateRepairRecord] = useState<User>(makeNewUser());
  const navigate = useNavigate();
  const {
    costumer,
    car,
    pricePerHour,
    shouldUnmount,
    unmountPrice,
    date,
    carParts,
    totalHours,
    totalPrice,
    addtionalCost,
    addtionalCostDescription,
    finished,
    billed,
    insuranceHours,
    costumerPrice,
    done,
    isEditable,
    isPriceCalculated,
    createdBy
  } = pericia;

  const IS_ALLOWED = (...roles: string[]) => roles.includes(user.permission);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      handleSetImage({ id_pericia: "", item: { ...image.item, file: selectedImage } });
    }
  };

  const handleImageDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!pericia.id) return;
    handleSetImage({ id_pericia: "", item: { ...image.item, description: e.target.value } });
  };

  const handleUploadImage = () => {
    if (!pericia.id) return;

    if (!image.item.file) {
      toast.error("Selecione uma imagem!");
      return;
    }

    handleSetImage({ id_pericia: pericia.id, item: { ...image.item } });
  };

  const handleCreatePayment = async (amount: number) => {
    setIsLoadingUpdatingTechnicianPaymentRecord(true);

    const { data, error } = await createTechnicianPaymentService.execute({
      id_pericia: periciaID as string,
      amount
    });

    setIsLoadingUpdatingTechnicianPaymentRecord(false);

    if (error) {
      toast.error(`erro ao criar pagamento!`);
      return;
    }

    toast.success("Pagamento atualizado com sucesso! Calculando...", { autoClose: 2000 });
    setTimeout(() => {
      fetchRepairRecords(periciaID as string);
    }, 2000);
  };

  const fetchRepairRecords = async (id_pericia: string) => {
    const res = await getFinishedRepairRecordsByPericiaId(id_pericia);
    if (res.error) {
      console.log(res.error);
      return;
    }

    setRepairRecords(res.data);
  };

  const onEditRepairRecord = () => {
    if (!periciaID) return;
    fetchRepairRecords(periciaID as string);
  };

  const handleCarFinishEditing = () => {
    setCarBeingCreated({ ...car });
  };

  const handleDeleteRepairRecord = (userID: string) => {
    if (!userID) return;
    setRepairRecordToDelete({ ...repairRecordToDelete, periciaID: periciaID as string, userID });
  };

  const handleCreateRepairRecord = () => {
    if (!periciaID) return;

    if (!userToCreateRepairRecord.id) {
      toast.error("Selecione um técnico!");
      return;
    }

    setRepairRecorToCreate({ ...repairRecorToCreate, periciaId: periciaID, userId: userToCreateRepairRecord.id });
    setUserToCreateRepairRecord(makeNewUser());
  };

  const handleUpdateCostumerPrice = (costumerPrice: number) => {
    updatePericiaCostumerPrice(costumerPrice);
    //update pericia
  };

  const handlePericiaNoteCreated = () => {
    toast.success("Observação sobre a perícia criada com sucesso", { autoClose: 2000 });
    refetchPericiaNotes();
  };

  const deletePericia = async () => {
    const res = await deletePericiaByCarId(car.id);
    if (res.error) {
      console.log(res.error);
      toast.error("Erro ao deletar pericia!");
      return;
    }

    toast.success("Pericia deletada com sucesso! Redirecionando para criação de nova perícia...", {
      autoClose: 600
    });

    setTimeout(() => {
      navigate("/");
    }, 1200);
  };

  useEffect(() => {
    if (!fetchedPericia) return;

    updatePericia(fetchedPericia);
  }, [fetchedPericia]);

  useEffect(() => {
    if (pericia.id === "") return;

    if (!periciaHasBeenFetched) {
      setPericiaHasBeenFetched(true);
      return;
    }

    setPericiaToUpdate(pericia);
  }, [pericia]);

  useEffect(() => {
    if (!periciaUpdated) return;

    toast.success("Pericia atualizada com sucesso!", { autoClose: 600 });
  }, [periciaUpdated]);

  useEffect(() => {
    if (!periciaID) return;
    fetchRepairRecords(periciaID);
  }, [periciaID]);

  useEffect(() => {
    if (!periciaID) return;
    setRepairRecordToDelete({ ...repairRecordToDelete, periciaID: periciaID });
  }, [periciaID]);

  useEffect(() => {
    if (!deleteRepairRecordData) return;

    toast.success("Registro de reparo deletado com sucesso!", { autoClose: 800 });
    fetchRepairRecords(periciaID as string);
    refetchPericia();
  }, [deleteRepairRecordData]);

  useEffect(() => {
    if (!createRepairRecord) return;

    toast.success("Registro de reparo criado com sucesso!", { autoClose: 800 });
    fetchRepairRecords(periciaID as string);
    refetchPericia();
  }, [createRepairRecord]);

  useEffect(() => {
    if (!fetchedTechnicianPaymentRecord) return;

    setAmountToPay(fetchedTechnicianPaymentRecord.amount);
  }, [fetchedTechnicianPaymentRecord]);

  useEffect(() => {
    if (!uploadPhotoRes) return;

    handleSetImage({ id_pericia: "", item: { file: null, description: "" } });
    toast.success("Foto adicionada com sucesso!", { autoClose: 800 });
    refetchPericiaPhotos();
  }, [uploadPhotoRes]);

  if (errorFetchingPericia) toast.error("Pericia não encontrada!");
  if (errorUpdatingPericia) toast.error("Erro ao atualizar pericia!");
  if (errorCreatingRepairRecord) toast.error("Erro ao criar registro de reparo!");
  if (deleteRepairRecordError) toast.error("Erro ao deletar registro de reparo!");
  //TODO: review
  // if (errorFetchingPericiaNotes) toast.error("Erro ao buscar observações sobre a perícia!");

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1">
        Atualizar Perícia
      </Typography>
      <PericiaHeader
        props={{
          costumer,
          car,
          shouldUnmount,
          pricePerHour,
          unmountPrice,
          date,
          updateCostumer: updatePericiaCostumer,
          updateCar: updatePericiaCar,
          updatePricePerHour: updatePericiaPricePerHour,
          updateShouldUnmount: updatePericiaShouldUnmount,
          updateUnmountPrice: updatePericiaUnmountPrice,
          updateDate: updatePericiaDate,
          createdBy: createdBy?.displayName || ""
        }}
      />
      <Typography component="h1" variant="h5">
        Pericia
      </Typography>
      <PericiaImg
        props={{
          findCarPart,
          updateCarPart
        }}
      />
      <PericiaFinishedInput props={{ finished, updateFinished: updatePericiaFinished }} />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && (
        <>
          <Typography component="h1" variant="h5" sx={{ mt: 5, mb: 3 }}>
            Tabela
          </Typography>
          <PericiaTable
            props={{
              carParts,
              totalHours,
              totalPrice,
              pricePerHour,
              unmountPrice,
              addtionalCost
            }}
          />
        </>
      )}
      <CreatePericiaNoteForm id_pericia={periciaID || ""} onPericiaNoteCreated={handlePericiaNoteCreated} />
      <BasicAccordion
        expanded={periciaNotes && periciaNotes.length > 0}
        title="Observações"
        children={
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Observações"
            style={{ width: "100%" }}
            value={formatPericiaNotes(periciaNotes || [])}
          />
        }
      />
      {/* {periciaComments && periciaComments.comments.length > 0 && <PericiaCommentsList comments={periciaComments.comments} />} */}
      <PericiaCommentsList comments={periciaComments?.comments || []} />
      <PericiaAddtionalCost
        props={{
          addtionalCost,
          addtionalCostDescription,
          updateAddtionalCost: updatePericiaAddtionalCost,
          updateAddtionalCostDescription: updatePericiaAddtionalCostDescription
        }}
      />
      <PericiaInsuranceForm
        props={{
          insuranceHours,
          updateInsuranceHours: updatePericiaInsuranceHours,
          insurancePrice: insuranceHours * pricePerHour
        }}
      />
      <PericiaCostumerPrice
        props={{
          costumerPrice,
          updatePericiaCostumerPrice: handleUpdateCostumerPrice
        }}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && (
        <>
          <PaperCard title="Adicionar reparo">
            <UserAutocompleteSearchBar props={{ user: userToCreateRepairRecord, updateUser: setUserToCreateRepairRecord }} />
            <LoadingButton
              variant="contained"
              loading={isLoadingCreatingRepairRecord}
              onClick={handleCreateRepairRecord}
              sx={{ marginTop: "1rem" }}
            >
              Adicionar
            </LoadingButton>
          </PaperCard>
          <TechnicianAmountToPayForm
            value={amountToPay}
            isLoading={isLoadingUpdatingTechnicianPaymentRecord}
            handleCreatePayment={handleCreatePayment}
          />
          <PericiaStatus
            props={{
              billed,
              updatePericiaIsBilled,
              done,
              updatePericiaIsDone,
              isEditable,
              isPriceCalculated,
              payed: false,
              updatePericiaIsPriceCalculated
            }}
          />
        </>
      )}
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN) && repairRecords.length > 0 && (
        <FinishedRepairRecordsList
          props={{
            repairRecords,
            amountToPay,
            hideActionsAndValues: !IS_ALLOWED(USER_ROLES.ADMIN),
            deleteRepairRecord: handleDeleteRepairRecord,
            onEditRepairRecord
          }}
        />
      )}
      {IS_ALLOWED(
        USER_ROLES.ADMIN,
        USER_ROLES.SALESMAN,
        USER_ROLES.LIMITED_ADMIN,
        USER_ROLES.PERICIA_CREATOR,
        USER_ROLES.USER_PRIVILEGED
      ) && (
        <>
          <PericiaInvoce props={{ invoiceNumber: invoice ? invoice.invoiceCustomCount : 0 }} />
        </>
      )}
      {periciaPhotos?.data && periciaPhotos.data.length > 0 && <PericiaPhotosList photos={periciaPhotos.data} />}
      {IS_ALLOWED(USER_ROLES.ADMIN) && (
        <CashPayedAmountComponent
          cashPayedAmount={pericia.cashPayedAmount || 0}
          id={pericia.id}
          disabled={pericia.cashPayedAmount > 0}
          repairRecountsCount={repairRecords.length || 1}
          onCashPayedAmountSaved={refetchPericia}
        />
      )}
      <UploadPericiaPhotoComponent
        isUploadingImage={isUploadingImage}
        done={done}
        image={image}
        handleImageChange={handleImageChange}
        handleImageDescriptionChange={handleImageDescriptionChange}
        handleUploadImage={handleUploadImage}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN) && (
        <TablePDFGenerator
          carParts={carParts}
          pricePerWorkingHour={pricePerHour}
          aditionalCost={addtionalCost}
          unmountPrice={unmountPrice}
          car={car}
          costumer={costumer.name}
          date={date}
          insuranceHours={insuranceHours}
          costumerPrice={costumerPrice}
        />
      )}
      <PDFGenerator
        disabled={isLoading}
        withCostumerPrice={false}
        props={{
          carParts,
          costumer,
          car,
          date,
          finished,
          shouldUnmount,
          unmountPrice,
          costumerPrice,
          addtionalCost,
          totalPrice,
          insurancePrice: insuranceHours * pricePerHour,
          addtionalCostDescription,
          totalHours
        }}
      />
      <PDFGenerator
        disabled={isLoading}
        withCostumerPrice={false}
        withTableHours={true}
        props={{
          carParts,
          costumer,
          car,
          date,
          finished,
          shouldUnmount,
          unmountPrice,
          costumerPrice,
          addtionalCost,
          totalPrice,
          insurancePrice: insuranceHours * pricePerHour,
          addtionalCostDescription,
          totalHours
        }}
      />
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.SALESMAN, USER_ROLES.LIMITED_ADMIN) && (
        <>
          <PDFGenerator
            disabled={isLoading}
            withCostumerPrice={true}
            props={{
              carParts,
              costumer,
              car,
              date,
              finished,
              shouldUnmount,
              unmountPrice,
              costumerPrice,
              addtionalCost,
              totalPrice,
              insurancePrice: insuranceHours * pricePerHour,
              addtionalCostDescription,
              totalHours
            }}
          />
          {IS_ALLOWED(USER_ROLES.ADMIN) && (
            <DeleteButton
              props={{
                deleteFunction: deletePericia,
                message: "Tem certeza que deseja excluir esta pericia? Esta ação não pode ser desfeita.",
                buttonText: "DELETAR PERICIA",
                loading: isLoading,
                disabled: false
              }}
            />
          )}
        </>
      )}
      <ToastContainer />
    </FlexContainer>
  );
};

const formatPericiaNotes = (notes: PericiaNotes[]) => {
  if (!notes) return "";

  return notes.map((note) => `\n- ${note.note}  (${note.createdAt.toLocaleString("pt-BR")})`);
};
