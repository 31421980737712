import { Route, Routes } from "react-router-dom";
import { PericiaCreatePage } from "../../pages/pericia/create-pericia.page";
import { PericiaUpdatePage } from "../../pages/pericia/update-pericia.page";
import { ListPericiasPage } from "../../pages/pericia/list-pericias.page";
import ListPericiasByDateRangePage from "../../pages/pericia/list-pericias-by-date-range.page";
import { GetPericiasMetricsPage } from "../../pages/pericia/get-pericias-metrics.page";
import { GetPericiasMetricsByCostumerPage } from "../../pages/pericia/get-pericias-metrics-by-costumer.page";
import { GetProfitMetricsPage } from "../../pages/metrics/get-profit-metrics.page";
import { GetPericiasMetricsByCostumersPage } from "../../pages/pericia/get-pericias-metrics-by-costumers.page";

const PericiasRoutes = () => {
  return (
    <Routes>
      <Route path="/add" element={<PericiaCreatePage />} />
      <Route path="/list" element={<ListPericiasPage />} />
      <Route path="/metrics" element={<GetPericiasMetricsPage />} />
      <Route path="/metrics/profit" element={<GetProfitMetricsPage />} />
      <Route path="/metrics/by-costumer" element={<GetPericiasMetricsByCostumerPage />} />
      <Route path="/metrics/by-costumers" element={<GetPericiasMetricsByCostumersPage />} />
      <Route path="/list/by-date" element={<ListPericiasByDateRangePage />} />
      <Route path=":periciaID" element={<PericiaUpdatePage />} />
    </Routes>
  );
};

export default PericiasRoutes;
